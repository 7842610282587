
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    label: {
      type: String,
      default: 'Address'
    },
    address: {
      type: Object,
      required: true
    }
  }
})
export default class AddressPanel extends Vue {}
