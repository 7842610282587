import { render, staticRenderFns } from "./AddressPanel.vue?vue&type=template&id=42a48705&scoped=true"
import script from "./AddressPanel.vue?vue&type=script&lang=ts"
export * from "./AddressPanel.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42a48705",
  null
  
)

export default component.exports